/* Carousel.css */

/* Default styles for all boxes */
.box {
    min-width: 23rem;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: flex-start;
  }
  
  /* Large screens */
  @media screen and (min-width: 1024px) {
    .box {
      width: calc(100% / 3); /* Three boxes on large screens */
    }
  }
  
  /* Medium screens */
  @media screen and (max-width: 1023px) and (min-width: 768px) {
    .box {
      width: calc(100% / 2); /* Two boxes on medium screens */
    }
  }
  
  /* Small screens */
  @media screen and (max-width: 767px) {
    .box {
      width: 100%; /* One box on small screens */
      justify-content: center;
      align-items: flex-start;
  
    }
  }
  