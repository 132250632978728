@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: BebasNeue;
  src: url("assets/fonts/BebasNeue-Regular.ttf") format("TrueType");
  font-weight: 700;

}

@font-face {
  font-family: BebasNeueProBook;
  src: url("assets/fonts/Bebas Neue Pro Book.otf") format("OpenType");
  font-weight: 300;

}
*{
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}


body {
  font-family: 'BebasNeueProBook';
}

input:focus ,textarea:focus {
  outline: 0px;
}


.box {
  min-width: 23rem;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
}
.boxImg {
  padding: 5px;
  width: 100%;
  height: 257px;
  background-position: center;
  object-fit: cover;
}
.boxImg1 {
  padding: 5px;
  width: 300px;
  height: 257px;
  background-position: center;
  object-fit: cover;
}
/* Large screens */
@media screen and (min-width: 1024px) {
  .box {
    width: calc(100% / 3); /* Three boxes on large screens */
  }
}

/* Medium screens */
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .box {
    width: calc(100% / 2); /* Two boxes on medium screens */
  }
}

/* Small screens */
@media screen and (max-width: 767px) {
  .box {
    width: 100%; /* One box on small screens */
    justify-content: center;
    align-items: flex-start;

  }
}




.load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.load div {
width: 20px;
height: 20px;
background-color: #FFBA5B;
border-radius: 50%;
margin: 0 5px;
animation-name: up-and-down;
animation-duration: 0.9s;
animation-iteration-count: infinite;
animation-direction: alternate;
}
.load .two {
animation-delay: 0.3s;
}
.load .three {
animation-delay: 0.6s;
}
@keyframes up-and-down {
to {
  opacity: 0.2;
  transform: translateY(-20px);
}
}

